import React from 'react';

import { Header, Footer, ScrollTop } from './components';
import { HomePage, AboutUs, TestimonialsPage, Faqs, BlogGrid, BlogList, BlogSingle, Login, Register, ForgotPwd, ChangePwd, SpeakerPage, SpeakerSingle, Venue, SponsorsPage, ScheduleTabPage, ScheduleListPage, ContactPage, Gallery, TicketsPage } from './sections';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



const App = () => {
  return (
    
    <div className='App'>

      <Header />

      <Routes>
 
        {/*Home*/}
        <Route path='/' element={<HomePage />} />

        {/*About*/}
        <Route path='/About-us' element={<AboutUs />} /> 
        <Route path='/Faqs' element={<Faqs />} />  
        <Route path='/Gallery' element={<Gallery />} />  
        <Route path='/Testimonials' element={<TestimonialsPage />} />

        {/*Pages*/}
        <Route path='/Tickets' element={<TicketsPage />} />   
        <Route path='/Venue' element={<Venue />} />  
        <Route path='/Sponsors' element={ <SponsorsPage />} />
        <Route path='/Login' element={<Login />} /> 
        <Route path='/Register' element={<Register />} /> 
        <Route path='/Forgot-pwd' element={<ForgotPwd />} /> 
        <Route path='/Change-pwd' element={<ChangePwd />} /> 

        {/*Speaker*/}
        <Route path='/Speakers' element={<SpeakerPage />} /> 
        <Route path='/Speakers/:id' element={<SpeakerSingle />} /> 
        <Route path='/Speakers/SpeakerSingle' element={<SpeakerSingle />} /> 
 
        {/*Schedule*/}
        <Route path='/Schedule-Tab' element={<ScheduleTabPage />} />  
        <Route path='/Schedule-List' element={<ScheduleListPage />} />  
  
        {/*Blogs*/}
        <Route path='/Blog-grid' element={<BlogGrid />} /> 
        <Route path='/Blog-list' element={<BlogList />} /> 
        <Route path='/Blogs/:id' element={<BlogSingle  />} />
        <Route path='/Blogs/Blog-Single' element={<BlogSingle />} />
 
        {/*Contact*/}
        <Route path='/Contact-us' element={<ContactPage />} />

      </Routes>

      <Footer />
      <ScrollTop />
      
    </div>
  )
}

export default App;
