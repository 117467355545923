import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom'; 

     
    
const Blog = (props) => { 
  const {id, image, date, heading, content, blog} = props;
  return (  
    <div className='blog-item' key={id}>

        <div className='image'>
          <img src={image} alt='Blog' />
          <span className='date'>{date.substring(0, date.indexOf(','))}</span>
        </div>
  
        <div className='content'> 
          <Link to={{
              pathname: '/Blogs/' + heading,
            }} 
            state= {blog}
            className='main-heading'>{heading}
          </Link>
          <p>{content}</p> 
          <Link to={{
              pathname: '/Blogs/' + heading,
            }} 
            state= {blog}
            className='btn'>read more
          </Link>  
        </div>

    </div>
  )
}

export default Blog;