import React, { useEffect, useState } from 'react';
import './ScheduleTab.css';

import { Heading, ScheduleButtons, ScheduleItem } from '../../../components';
import { schedule } from '../../../constants'; 
 
const ScheduleTab = () => {

    const [selected, setSelected] = useState("day1"); 
    const [data, setData] = useState(schedule[0]);

    const list = [
      { 
        id: "day1",  
        title: "Day 01",
      },
      {
        id: "day2",
        title: "Day 02",
      },
      {
        id: "day3",
        title: "Day 03",
      },
    ];

    useEffect(() => {
      switch (selected) {
        case "day2":
          setData(schedule[1]);
          break;
        case "day3":
          setData(schedule[2]);
          break;
        default:
          setData(schedule[0]);
      }
    }, [selected]);

    return (
        
      <section className='schedule'> 

        <Heading title={'event schedule'} />  

        <ul className='schedule-btn-tabs'>
        { 
          list.map((item) => {
          return <ScheduleButtons
            id={item.id}  
            key={item.id} 
            title={item.title}
            active={selected === item.id} 
            setSelected={setSelected} />
          })
        }
        </ul> 

        <div className='box-container'>
          <ScheduleItem  
            key={data.id} 
            id={data.id} 
            date={data.date}
            day={data.day}
            program={data.program}
          />
        </div>

      </section>

  )
}

export default ScheduleTab;