import React from 'react';
import './ScheduleButtons.css';
 
 

const ScheduleButtons = ({id, title, active, setSelected}) => {
  return ( 
    <li
        className={active ? "button active" : "button"}
        onClick={() => setSelected(id)}>
        {title} 
    </li>
  )
}
   
export default ScheduleButtons; 