import React from 'react';
import './SpeakerSingle.css';

import { PageTitle } from '../../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import { useLocation } from "react-router-dom";
 

 
const SpeakerSingle = () => {   

  const location = useLocation();
  const team = location.state; 

  const account = team.accounts; 

  return (
    <>
      <PageTitle title={'speaker single'} page={'speaker single'} />
        <section className='speaker-single'>

          <div className='heading'>
            <h2>speaker details</h2>
          </div>

          <div className='box-container'>

            <div className='image'>
              <img src={team.image} alt="" />
              <div className='social-connect'>
                <a className='icon' href={account.facebook}><FaFacebookF /></a>
                <a className='icon' href={account.twitter}><FaTwitter /></a>
                <a className='icon' href={account.instagram}><FaInstagram /></a>
                <a className='icon' href={account.linkedin}><FaLinkedin /></a> 
              </div>
            </div>

            <div className='content'>

              <h2>{team.name}</h2>  
              <h3>{team.title}</h3>

              <div className='speaker-about'>
              `{
                  (team.about).map((item) => {
                      return <p key={item.id}>{item.content}</p>
                  })
                }
              </div>

              <div className='speaker-experience'>
                <h5>experience:</h5>
                `{
                    (team.experience).map((item) => {
                      return <p key={item.id}>{item.content}</p>
                    })
                  }
              </div>
                
              <div className='speaker-testimonials'>
                <h5>testimonials:</h5>
                `{
                  (team.testimonials).map((item) => {
                    return <p key={item.id}>"{item.content}" <span>- {item.author}</span> </p>
                  })
                }    
              </div>

            </div>

          </div>


        </section>
    </>
  ) 
}

export default SpeakerSingle;