import { images } from ".";

const testimonials = [ 
    { 
        id: 1,
        image: images.testimonial1, 
        name: 'jason roy', 
        title: 'CEO',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
    { 
        id: 2,
        image: images.testimonial2, 
        name: 'evelyn jones', 
        title: 'manager',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
    { 
        id: 3,
        image: images.testimonial3, 
        name: 'viraj patel', 
        title: 'client',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
];

export default testimonials;