import React, {useState, useEffect} from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo } from '../../components';
import { navbar } from '../../constants';

import { FaBars } from 'react-icons/fa';
 
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
  
  
    
const Header = () => { 
  const [click, setClick] = useState(false); 
  const [isOpen, setOpen] = useState(false);

  const {pathname} = useLocation();

  const handleClick = () => {
    setClick(true); 
    setOpen(true); 
  }

  const closeMenuBar = () => {
    setClick(false);
    setOpen(false); 
  }

  /* Close the Navbar between navigation */
  useEffect(() => {
    setOpen(false); 
  }, [ pathname ]);
     
  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', stickyHeader);
    return () => {
        window.removeEventListener('scroll', stickyHeader);
    };
  });

  const stickyHeader = (e) => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('active') : header.classList.remove('active');
  };

  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false);  
  };


  return (  

    <nav className='header'>

      <div className='icon menu-btn' onClick={handleClick}><FaBars /></div>
 
      <Logo />  

      {(click && isOpen) && <MobileNavbar close={closeMenuBar} />}

      <div className='navbar' >
        {
          navbar.map((item) => {
            return <HeaderItem key={item.id} {...item} />
          })
        }  
      </div>

      <Link className='buy-ticket' to={'Tickets'}>Buy Ticket</Link>

    </nav>
   )
 }
 
 export default Header;