import { FaUser, FaFlag, FaUsers, FaCalendarAlt } from 'react-icons/fa';

  
const counter = [
    { 
        id: 1,
        icon: <FaUser />,
        title: 'Speakers',
        count: '100',
    },
    {
        id: 2,
        icon: <FaFlag />,
        title: 'Countries',
        count: '50',
    },
    {
        id: 3,
        icon: <FaUsers />,
        title: 'Attendees',
        count: '1000',
    },
    {
        id: 4,
        icon: <FaCalendarAlt />,
        title: 'Events',
        count: '50',
    },
 
];

export default counter;