import React from 'react';

import { PageTitle } from '../../../components';
import { Tickets } from '../../../sections';



const TicketsPage = () => { 
  return ( 
    <>
      <PageTitle title={'Event Tickets'} page={'Event Tickets'} />
      <Tickets />
    </>
 
  )
} 

export default TicketsPage;