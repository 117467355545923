import React from 'react'; 
import './ContactUs.css'; 

import { Contact } from '../../../components';
 
   
   
const ContactUs = () => {  
  return <Contact className='home-contact' />
}

export default ContactUs;