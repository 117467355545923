import React from 'react';

import {Home, About, Counter, Testimonials, Blogs, ScheduleTab, Tickets, Sponsors, Speakers, ContactUs} from '../../sections'; 

 

const HomePage = () => { 
  return ( 
    <div className='homepage'>
      <Home />
      <About />
      <Counter />
      <ScheduleTab /> 
      <Speakers />
      <Tickets />
      <Testimonials />
      <Blogs />
      <Sponsors />
      <ContactUs />
    </div>
  )
}

export default HomePage;