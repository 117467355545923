import speakers from './speakers';
import blogs from './blogs';



const navbar = [  
    {
        id: 1,
        mainLink : {link: '/', title: 'home'},
        subLink: [],
    },
    {
        id: 2,
        mainLink : {link: '#', title: 'about'},
        subLink: [
            {id: 1, link: '/About-us', title: 'about us'},
            {id: 2, link: '/Faqs', title: 'faqs'},
            {id: 3, link: '/Gallery', title: 'Gallery'},
            {id: 4, link: '/Testimonials', title: 'testimonials'}, 

        ],
    },
    {
        id: 3,
        mainLink : {link: '#', title: 'pages'},
        subLink: [
            {id: 1, link: '/Tickets', title: 'tickets'},
            {id: 2, link: '/Venue', title: 'venue'},
            {id: 3, link: '/Sponsors', title: 'sponsors'},
            {id: 4, link: '/Login', title: 'login'},
            {id: 5, link: '/Register', title: 'register'},
            {id: 6, link: '/Forgot-pwd', title: 'forgotPwd'},
            {id: 7, link: '/Change-pwd', title: 'changePwd'},
        ],
    },
    {
        id: 4,
        mainLink : {link: '#', title: 'speaker'},
        subLink: [
            {id: 1, link: '/Speakers', title: 'speakers'},
            {id: 2, link: '/Speakers/SpeakerSingle', title: 'speaker single', state: speakers[0]}, 
        ],
    }, 
    {
        id: 5,
        mainLink : {link: '#', title: 'schedule'},
        subLink: [
            {id: 1, link: '/Schedule-Tab', title: 'schedule tab'},
            {id: 2, link: '/Schedule-List', title: 'schedule list'}, 
        ],
    }, 
    {
        id: 6,
        mainLink : {link: '#', title: 'blog'},
        subLink: [
            {id: 1, link: '/Blog-grid', title: 'blog grid'},
            {id: 2, link: '/Blog-list', title: 'blog list'},
            {id: 3, link: '/Blogs/Blog-Single', title: 'blog single', state: blogs[0]},
        ],
    },
    {
        id: 7,
        mainLink : {link: '/Contact-us', title: 'contact'},
        subLink: [],
    },
];

export default navbar;