import React from 'react';
import './BlogSidebar.css';

import { SidebarHeading } from '../../components';
import { blogCategories, posts, tags } from '../../constants';

import { BsSearch } from 'react-icons/bs';

import { Link } from 'react-router-dom';  
  
 
  
const BlogSidebar = () => { 
  return (  
    <div className='sidebar blog-sidebar'>
 
        <div className='search sidebar-item'>
            <SidebarHeading title='search' />
            <div className='box-container'>
                <div className='input-box'>
                    <input type="search" id="search" placeholder="search..." />
                    <label htmlFor="search"><BsSearch /></label>
                </div>
            </div>
        </div>


        <div className='category sidebar-item'>
            <SidebarHeading title='categories' />
            <div className='box-container'>
            {
                blogCategories.map((category) => {
                    return(
                        <div className='item' key={category.id}>
                            <a href="">{category.title}</a>
                            <p>({category.quantity})</p>
                        </div>
                    )
                })
            }
            </div>   
        </div>  


        <div className='recent-post sidebar-item'>
            <SidebarHeading title='recent posts' /> 
            <div className='box-container'>
            {
                posts.map((post) => { 
                    return( 
                        <div className='blog-item' key={post.id}>
                            
                            <img src={post.image} alt='Event' />
                    
                            <div className='content'>
                                <Link to={{
                                    pathname: '/Blogs/' + post.heading,
                                    }} 
                                    state= {post}
                                    className='main-heading'>{post.heading}
                                </Link>
                                <span>{post.date}</span>
                            </div>
                    
                        </div>
                        )
                })
            } 
            </div>
        </div>


        <div className='tags sidebar-item'>
            <SidebarHeading title='tags' />
            <div className='box-container'>
                {
                    tags.map((tag) => {
                        return <div className='tag-item' key={tag.id}>{tag.title}</div>
                    })
                }
            </div>
        </div>
        

    </div>
  )
}

export default BlogSidebar;