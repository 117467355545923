import React from 'react';
import './Tickets.css';

import { Heading } from '../../../components';
import { tickets } from '../../../constants';

 

const Tickets = () => {    

  return (   
    <>  
      <section className='ticket'> 

        <Heading title={'Event Tickets'} />

        <div className='box-container'>
          { 
          tickets.map((ticket) => {  
            return( 
              <div className='ticket-item' key={ticket.id}>
                <h3>{ticket.title}</h3>
                <div className='price'><span>$</span>{ticket.price}<span>/person</span></div>
                <ul>{(ticket.list).map(item => {return <li key={item.id}>{item.name}</li>})}</ul>
                <a href="#" className='btn'>buy ticket</a>
              </div>
            )
          })
          }
        </div>
      </section>
    </>
  )
}
 
export default Tickets;