import React, { useState, useEffect } from 'react';
import './CountdownTimer.css';

 

const CountdownTimer = ({ targetDate }) => {
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    const timerId = setInterval(() => {
      setRemainingTime(calculateRemainingTime()); 
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  function calculateRemainingTime() {
    const timeDiff = new Date(targetDate) - new Date();
    if (timeDiff < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return {
      days: days,
      hours: hours % 24,
      minutes: minutes % 60,
      seconds: seconds % 60,
    };
  }

  return (
    <div className='counter'>
      <div className='counter-item'>
          <h3>{remainingTime.days}</h3>
          <span>day</span>
      </div>
      <div className='counter-item'>
          <h3>{remainingTime.hours}</h3>
          <span>hour</span>
      </div>
      <div className='counter-item'>
          <h3>{remainingTime.minutes}</h3>
          <span>minutes</span>
      </div>
      <div className='counter-item'>
          <h3>{remainingTime.seconds}</h3>
          <span>seconds</span>
      </div>
    </div>
  );
}

export default CountdownTimer;