import React, {Component} from 'react';
import axios from "axios";
import './Contact.css';

import { Button, Heading } from '../../components';
import { contactContent } from '../../constants'; 

const API_PATH = 'https://eventia-react.asdesignsgalaxy.com/api/phpmailer/submit.php';
 
  
  
class Contact extends Component{ 
  constructor(props) { 
    super(props); 
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      mailSent: false,
      error: null
    }
  }

  // when submit btn is clicked
  handleSubmitForm = (e) => {

    e.preventDefault();

    const submitBtn = document.querySelector('#submit .btn');
    submitBtn.innerText = 'please wait';

    this.setState({
      mailSent: false
    })

    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("subject", this.state.subject);
    formData.append("message", this.state.message);

    axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData
    })
    .then(result => {
        console.log(result)
        this.setState({
          mailSent: result.data.sent,
          name: '', email: '', subject: '', message: ''
        })
        submitBtn.innerText = 'send message';
    })
    .catch(error => this.setState({ error: error.message }));
    console.log(this.state);
  }
 
  render(){
    return (

    <section className={`contact ${this.props.className}`} id='contact'>
      
      <Heading title={'contact us'} />

      <div className='contact-info'>
        {
        contactContent.map((contact) => {
          let details = contact.content;
          return (
          <div className='info-item' key={contact.id}>
            <div className='icon'>{contact.icon}</div>
            <h3>{contact.title}</h3> 
            {details.map(item => {return <p key={item.id}>{item.value}</p>})}
          </div>)
        })
        }
      </div>

      <div className='contact-form-container'> 

        <form method="post" className='contact-form' id="contactUs-Form">

          <h3>get in touch</h3>

          <input type="text" name="name" className='box' id="name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} placeholder="name" required />
          <input type="email" name="email" className='box' id="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}  placeholder="email" required />
          <input type="text" name="subject" className='box' id="subject" value={this.state.subject} onChange={e => this.setState({ subject: e.target.value })} placeholder="subject" />
          <textarea cols="30" rows="10" name="comment" id="comment" className='box' value={this.state.message} onChange={e => this.setState({ message: e.target.value })} placeholder="message"></textarea>
          <div className='msg-alert'>
            
            <button type="submit" onClick={e => this.handleSubmitForm(e)} name="submit" id="submit"><Button title={"send message"} /></button>
            
            {this.state.mailSent &&
                <span className="alert">Message sent successfully.</span>
            }

          </div>
          
        </form>

      </div> 

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924234.6303368191!2d66.59494515823903!3d25.193389461148573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1645287442276!5m2!1sen!2s" 
        width="600" height="450" allowFullScreen loading="lazy"></iframe>

     </section>

   )
 }
}
 
 export default Contact;