import React from 'react';
import './Venue.css';

import { PageTitle, Heading } from '../../../components';
import { venueContent } from '../../../constants';



const Venue = () => {      

  return (  
    <>

      <PageTitle title={'event venue'} page={'venue'} />

      <section className='venue'>

        <Heading title={'event venue'} />

        <div className='box-container'>

          <div className='venue-info'>
            {
            venueContent.map((info) => {
              let details = info.content;
              return <div className='info-item' key={info.id}>
                <div className='icon'>{info.icon}</div>
                <h3>{info.title}</h3> 
                {details.map(item => {return <p key={item.id}>{item.value}</p>})}
              </div>
            })
            }
          </div>

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d924234.6303368191!2d66.59494515823903!3d25.193389461148573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e06651d4bbf%3A0x9cf92f44555a0c23!2sKarachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1645287442276!5m2!1sen!2s" 
          width="600" height="450" allowFullScreen loading="lazy"></iframe>

        </div>
        
      </section>
    </>
  )
}
 
export default Venue;