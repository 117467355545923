import React from 'react';
import './GalleryItem.css';
 
import { FaSearch } from 'react-icons/fa';

   
  
const GalleryItem = ({id, image, title, popup}) => {
  
  return (
    <div className='gallery-item' key={id}>
      <img src={image} alt='Gallery image' /> 
      <div className='content'> 
        <h4>{title}</h4>
        <a onClick={() => {popup(id - 1)}}><FaSearch className='icon' /></a> 
      </div>
    </div> 
  ) 
}

export default GalleryItem;