import React from 'react';
import './BlogGrid.css';

import { Blog, PageTitle, PagesNo, BlogSidebar } from '../../../components';
import { blogContent } from '../../../constants';

 

const BlogGrid = () => {     
  const size = 6;   
  const blogs = blogContent.slice(0, size); 
  return (  
    <>
      <PageTitle title={'blog grid'} page={'blog grid'} />
      <section className='blog grid'>
        <BlogSidebar />
        <div className='blog-container grid'>
          <div className='blog-items'>
            { 
              blogs.map((blog) => {  
                return( 
                <Blog  
                  key={blog.id} 
                  image={blog.image} 
                  date={blog.date}
                  admin={blog.admin}
                  heading={blog.heading}
                  content={blog.content} 
                  blog={blog} 
                />)
              })
            }
          </div>
          <PagesNo />
        </div>
      </section>
    </>
  )
}
 
export default BlogGrid;