import React from 'react';
import './Footer.css';

import { Logo } from '../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import { Link } from 'react-router-dom';

   
  
const Footer = () => {  
  
  return (
    <footer className='footer'>

      <div className='footer-item'> 
        <Logo /> 
        <p>Discover the power of connections. Empowering change, shaping the future. Where minds meet and ideas take flight. Collaborate, learn, and grow.</p>
      </div> 

      <div className='footer-item'>
        <div className='social'>
          <a href="/#"><FaFacebookF className='icon' /></a>
          <a href="/#"><FaTwitter className='icon' /></a>
          <a href="/#"><FaInstagram className='icon' /></a>
          <a href="/#"><FaLinkedin className='icon' /></a>
        </div>
      </div>

      <div className='footer-item'> 
        <div className='info'>
          <p><Link to='/'>home</Link></p> 
          <p><Link to='/about-us'>about</Link></p>
          <p><Link to='/Speakers'>speaker</Link></p> 
          <p><Link to='/Schedule-Tab'>schedule</Link></p>
          <p><Link to='/Blog-grid'>blog</Link></p>
          <p><Link to='/Contact-us'>contact</Link></p>
          <p><Link to='/Venue'>venue</Link></p>
        </div>
      </div> 

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;