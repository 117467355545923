import React from 'react';
import './Comment.css'; 


 
const Comment = (props) => {
     
    const {id, image, name, date, content} = props; 
    
    return (
        <div className='comment' key={id}>
            <div className='image'>  
                <img src={image} alt='' />
            </div>
            <div className='content'>
                <div className='intro'>
                    <div className='name'>
                        <h6>{name}</h6>
                        <p>{date}</p>
                    </div>
                    <div className='icon-item'>reply</div>
                </div>
                <p className='text'>{content}</p>
            </div>
        </div>
    )
}

export default Comment;