import React from 'react'; 
import './SpeakerItem.css'; 

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import { Link } from 'react-router-dom'; 


     
const SpeakerItem = (props) => {  
    const {id, image, name, title, accounts, speaker} = props;
    return (
        <div className='speaker-item' key={id}>
            <div className='image'>
                <img src={image} alt='Speaker Pic' /> 
            </div>
            <div className='content'>
                <Link to={{
                        pathname: '/Speakers/' + name,
                    }}     
                    state={speaker}><h3>{name}</h3>
                </Link>
                <p>{title}</p>
                <div className='social-connect'>
                    <a className='icon' href={accounts.facebook}><FaFacebookF /></a>
                    <a className='icon' href={accounts.twitter}><FaTwitter /></a>
                    <a className='icon' href={accounts.instagram}><FaInstagram /></a>
                    <a className='icon' href={accounts.linkedin}><FaLinkedin /></a> 
                </div> 
            </div>
        </div>
    )
}

export default SpeakerItem;