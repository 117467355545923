import React from 'react';
import './Home.css';

import { Button, CountdownTimer } from '../../../components';

 

const Home = () => { 

  return ( 

    <section className='home'>
 
      <div className='content'> 
          
        <h1>Largest Tech Conference 2022</h1>
        <p>01-03, jan, 2017, korea</p>
        <div className='btn-container'>
          <Button link={'Schedule-Tab'} title={'see schedule'} />
          <Button link={'Tickets'} title={'buy ticket'} />
        </div>

        <CountdownTimer targetDate={"dec 31, 2023"} />

      </div>

    </section>

  )
}

export default Home;