import { images } from '.';


const sponsors = [  
    { 
        id: 1, 
        category: 'platinum',
        list: [ 
            {id: 1, image: images.platinumSponsor1},
            {id: 2, image: images.platinumSponsor2},
            {id: 3, image: images.platinumSponsor3},
            {id: 4, image: images.platinumSponsor4},
        ],
    },
    { 
        id: 2, 
        category: 'gold',
        list: [ 
            {id: 1, image: images.goldSponsor1},
            {id: 2, image: images.goldSponsor2},
            {id: 3, image: images.goldSponsor3},
            {id: 4, image: images.goldSponsor4},
        ],
    },
    { 
        id: 3, 
        category: 'silver',
        list: [ 
            {id: 1, image: images.silverSponsor1},
            {id: 2, image: images.silverSponsor2},
            {id: 3, image: images.silverSponsor3},
            {id: 4, image: images.silverSponsor4},
        ],
    },

];

export default sponsors;