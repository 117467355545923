import React from 'react';
import './AboutUs.css';

import { PageTitle } from '../../../components';
import { About, Testimonials, Counter, Speakers } from '../../../sections';



const AboutUs = () => {  
  return (
    <> 
      <PageTitle title={'about us'} page={'about'} />
      <section className='about-us'>
        <About />
        <Counter />
        <Speakers />
        <Testimonials />
      </section> 
    </>
  )
}

export default AboutUs;