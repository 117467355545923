import { images } from '../constants'; 
 
 const schedule = [ 
    {   
        id: 1,  
        date: '1st January 2023',
        day: 'Monday',
        program: [
            {id:1, class: 'break', timing: '9:00 - 10:00', title: 'register'},
            {id:2, timing: '10:00 - 12:00', speakerPic: images.speaker1, speakerName: 'George Alexander', title: 'Entrepreneurism and Business Growth', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:3, timing: '12:00 - 02:00', speakerPic: images.speaker6, speakerName: 'Maria Hernandez', title: 'Applications of AI in Customer Service', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:4, class: 'break', timing: '2:00 - 3:00', title: 'Coffee & Lunch Break'},
            {id:5, timing: '3:00 - 6:00', speakerPic: images.speaker3, speakerName: 'James Johnson', title: 'New and innovative technologies', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:6, class: 'break', timing: '6:00 - 7:00', title: 'Dinner Break'},
            {id:7, timing: '7:00 - 9:00', speakerPic: images.speaker4, speakerName: 'Wendy Hills', title: 'Application Development and Data Modeling', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
        ],
    },
    {   
        id: 2,  
        date: '2nd January 2023',
        day: 'tuesday',
        program: [
            {id:1, timing: '9:00 - 11:00', speakerPic: images.speaker2, speakerName: 'Michelle Goszko', title: 'Technology Updates – Social Media, Marketing Automation, Cybersecurity', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:2, timing: '11:00 - 2:00', speakerPic: images.speaker3, speakerName: 'James Johnson', title: 'Digital Disruption – Augmented Humans, Superhumans, Humanicity, Blockchain', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:3, class: 'break', timing: '2:00 - 3:00', title: 'Coffee & Lunch Break'},
            {id:4, timing: '3:00 - 6:00', speakerPic: images.speaker4, speakerName: 'Wendy Hills', title: 'Developments in machine learning technologies', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:5, class: 'break', timing: '6:00 - 7:00', title: 'Dinner Break'},
            {id:6, timing: '7:00 - 9:00', speakerPic: images.speaker5, speakerName: 'David Smith', title: 'Innovation to Reality', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
        ],
    },
    {   
        id: 3,  
        date: '3rd january 2023',
        day: 'wednesday',
        program: [
            {id:1, timing: '9:00 - 12:00', speakerPic: images.speaker7, speakerName: 'David Steele', title: 'Making AI Work for All', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:2, timing: '12:00 - 02:00', speakerPic: images.speaker8, speakerName: 'Jill Kennare', title: 'Programming languages, platforms, and systems', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:3, class: 'break', timing: '2:00 - 3:00', title: 'Coffee & Lunch Break'},
            {id:4, timing: '3:00 - 6:00', speakerPic: images.speaker1, speakerName: 'George Alexander', title: 'Compliance with security standards', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
            {id:5, class: 'break', timing: '6:00 - 7:00', title: 'Dinner Break'},
            {id:6, timing: '7:00 - 9:00', speakerPic: images.speaker2, speakerName: 'Michelle Goszko', title: 'Tools for response coding', content: 'Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Aperiam Voluptatem Ducimus Consectetur Cumque Excepturi Temporibus Vitae, Incidunt Ipsa Ad Dignissimos Quo.'},
        ],
    },
 
];

export default schedule;