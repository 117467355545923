import React from 'react';
import './Login.css';

import { Button, PageTitle } from '../../../components';

import { Link } from 'react-router-dom';


  
const Login = () => {    
  return (   
    <> 
      <PageTitle title={'login'} page={'login'} /> 
      <section className='login'>
        <form className='form account' action="example.php">
            <h3>login</h3>
            <input type="email" name="email" placeholder="enter your email" id="email" className='box' />
            <input type="password" name="password" placeholder="enter your password" id="password" className='box' />
            <div className='info'>
              <div className='remember'>
                <input type="checkbox" name="remember" id="remember-me" />
                <label htmlFor="remember-me"> remember me </label>
              </div>
              <div className='forgot'>
                <Link to='/forgot-pwd' className='link'>forgot password?</Link>
              </div>
            </div>
            <button type="submit" name="submit" id="submit"><Button title={"login"} /></button>

            <p>don't have an account? <Link to='/register' className='link'>register</Link></p>
        
        </form>
      </section> 
    </>

  )
}

export default Login;