/*Global*/
import pageTitle from '../assets/PageTitle/PageTitle.jpg';

/*Home*/
import home from '../assets/Home/Home.jpg'; 

/*About*/ 
import about1 from '../assets/About/About-1.jpg';
import about2 from '../assets/About/About-2.jpg';
import about3 from '../assets/About/About-3.jpg';
import about4 from '../assets/About/About-4.jpg';

/*Speakers*/
import speaker1 from '../assets/Speaker/Speaker-1.jpg';
import speaker2 from '../assets/Speaker/Speaker-2.jpg';
import speaker3 from '../assets/Speaker/Speaker-3.jpg';
import speaker4 from '../assets/Speaker/Speaker-4.jpg';
import speaker5 from '../assets/Speaker/Speaker-5.jpg';
import speaker6 from '../assets/Speaker/Speaker-6.jpg';
import speaker7 from '../assets/Speaker/Speaker-7.jpg';
import speaker8 from '../assets/Speaker/Speaker-8.jpg';

/*Testimonials*/
import testimonial1 from '../assets/Testimonials/Pic-1.jpg';
import testimonial2 from '../assets/Testimonials/Pic-2.jpg';
import testimonial3 from '../assets/Testimonials/Pic-3.jpg';

/*Sponsors*/
import platinumSponsor1 from '../assets/Sponsors/Platinum/Sponsor-1.png';
import platinumSponsor2 from '../assets/Sponsors/Platinum/Sponsor-2.png';
import platinumSponsor3 from '../assets/Sponsors/Platinum/Sponsor-3.png';
import platinumSponsor4 from '../assets/Sponsors/Platinum/Sponsor-4.png';

import goldSponsor1 from '../assets/Sponsors/Gold/Sponsor-1.png';
import goldSponsor2 from '../assets/Sponsors/Gold/Sponsor-2.png';
import goldSponsor3 from '../assets/Sponsors/Gold/Sponsor-3.png';
import goldSponsor4 from '../assets/Sponsors/Gold/Sponsor-4.png';

import silverSponsor1 from '../assets/Sponsors/Silver/Sponsor-1.png';
import silverSponsor2 from '../assets/Sponsors/Silver/Sponsor-2.png';
import silverSponsor3 from '../assets/Sponsors/Silver/Sponsor-3.png';
import silverSponsor4 from '../assets/Sponsors/Silver/Sponsor-4.png';

/*Gallery*/
import gallery1 from '../assets/Gallery/Gallery-1.jpg';
import gallery2 from '../assets/Gallery/Gallery-2.jpg';
import gallery3 from '../assets/Gallery/Gallery-3.jpg';
import gallery4 from '../assets/Gallery/Gallery-4.jpg';
import gallery5 from '../assets/Gallery/Gallery-5.jpg';
import gallery6 from '../assets/Gallery/Gallery-6.jpg';
import gallery7 from '../assets/Gallery/Gallery-7.jpg';
import gallery8 from '../assets/Gallery/Gallery-8.jpg';
import gallery9 from '../assets/Gallery/Gallery-9.jpg';

/*Blogs*/
import blog1 from '../assets/Blog/Blogs/1/Main.jpg';
import blog1gallery1 from '../assets/Blog/Blogs/1/Gallery/1.jpg';
import blog1gallery2 from '../assets/Blog/Blogs/1/Gallery/2.jpg';
import blog1gallery3 from '../assets/Blog/Blogs/1/Gallery/3.jpg';
import blog1gallery4 from '../assets/Blog/Blogs/1/Gallery/4.jpg';
import blog1gallery5 from '../assets/Blog/Blogs/1/Gallery/5.jpg';
import blog1gallery6 from '../assets/Blog/Blogs/1/Gallery/6.jpg';
import blog1comment1 from '../assets/Blog/Blogs/1/Comments/Pic-1.jpg';
import blog1comment2 from '../assets/Blog/Blogs/1/Comments/Pic-2.jpg';
import blog1comment3 from '../assets/Blog/Blogs/1/Comments/Pic-3.jpg';

import blog2 from '../assets/Blog/Blogs/2/Main.jpg';
import blog2gallery1 from '../assets/Blog/Blogs/2/Gallery/1.jpg';
import blog2gallery2 from '../assets/Blog/Blogs/2/Gallery/2.jpg';
import blog2gallery3 from '../assets/Blog/Blogs/2/Gallery/3.jpg';
import blog2gallery4 from '../assets/Blog/Blogs/2/Gallery/4.jpg';
import blog2gallery5 from '../assets/Blog/Blogs/2/Gallery/5.jpg';
import blog2gallery6 from '../assets/Blog/Blogs/2/Gallery/6.jpg';
import blog2comment1 from '../assets/Blog/Blogs/2/Comments/Pic-1.jpg';
import blog2comment2 from '../assets/Blog/Blogs/2/Comments/Pic-2.jpg';
import blog2comment3 from '../assets/Blog/Blogs/2/Comments/Pic-3.jpg';

import blog3 from '../assets/Blog/Blogs/3/Main.jpg';
import blog3gallery1 from '../assets/Blog/Blogs/3/Gallery/1.jpg';
import blog3gallery2 from '../assets/Blog/Blogs/3/Gallery/2.jpg';
import blog3gallery3 from '../assets/Blog/Blogs/3/Gallery/3.jpg';
import blog3gallery4 from '../assets/Blog/Blogs/3/Gallery/4.jpg';
import blog3gallery5 from '../assets/Blog/Blogs/3/Gallery/5.jpg';
import blog3gallery6 from '../assets/Blog/Blogs/3/Gallery/6.jpg';
import blog3comment1 from '../assets/Blog/Blogs/3/Comments/Pic-1.jpg';
import blog3comment2 from '../assets/Blog/Blogs/3/Comments/Pic-2.jpg';
import blog3comment3 from '../assets/Blog/Blogs/3/Comments/Pic-3.jpg';

import blog4 from '../assets/Blog/Blogs/4/Main.jpg';
import blog4gallery1 from '../assets/Blog/Blogs/4/Gallery/1.jpg';
import blog4gallery2 from '../assets/Blog/Blogs/4/Gallery/2.jpg';
import blog4gallery3 from '../assets/Blog/Blogs/4/Gallery/3.jpg';
import blog4gallery4 from '../assets/Blog/Blogs/4/Gallery/4.jpg';
import blog4gallery5 from '../assets/Blog/Blogs/4/Gallery/5.jpg';
import blog4gallery6 from '../assets/Blog/Blogs/4/Gallery/6.jpg';
import blog4comment1 from '../assets/Blog/Blogs/4/Comments/Pic-1.jpg';
import blog4comment2 from '../assets/Blog/Blogs/4/Comments/Pic-2.jpg';
import blog4comment3 from '../assets/Blog/Blogs/4/Comments/Pic-3.jpg';

import blog5 from '../assets/Blog/Blogs/5/Main.jpg';
import blog5gallery1 from '../assets/Blog/Blogs/5/Gallery/1.jpg';
import blog5gallery2 from '../assets/Blog/Blogs/5/Gallery/2.jpg';
import blog5gallery3 from '../assets/Blog/Blogs/5/Gallery/3.jpg';
import blog5gallery4 from '../assets/Blog/Blogs/5/Gallery/4.jpg';
import blog5gallery5 from '../assets/Blog/Blogs/5/Gallery/5.jpg';
import blog5gallery6 from '../assets/Blog/Blogs/5/Gallery/6.jpg';
import blog5comment1 from '../assets/Blog/Blogs/5/Comments/Pic-1.jpg';
import blog5comment2 from '../assets/Blog/Blogs/5/Comments/Pic-2.jpg';
import blog5comment3 from '../assets/Blog/Blogs/5/Comments/Pic-3.jpg';

import blog6 from '../assets/Blog/Blogs/6/Main.jpg';
import blog6gallery1 from '../assets/Blog/Blogs/6/Gallery/1.jpg';
import blog6gallery2 from '../assets/Blog/Blogs/6/Gallery/2.jpg';
import blog6gallery3 from '../assets/Blog/Blogs/6/Gallery/3.jpg';
import blog6gallery4 from '../assets/Blog/Blogs/6/Gallery/4.jpg';
import blog6gallery5 from '../assets/Blog/Blogs/6/Gallery/5.jpg';
import blog6gallery6 from '../assets/Blog/Blogs/6/Gallery/6.jpg';
import blog6comment1 from '../assets/Blog/Blogs/6/Comments/Pic-1.jpg';
import blog6comment2 from '../assets/Blog/Blogs/6/Comments/Pic-2.jpg';
import blog6comment3 from '../assets/Blog/Blogs/6/Comments/Pic-3.jpg';

import post1 from '../assets/Blog/Recent-Posts/1/Main.jpg';
import post1gallery1 from '../assets/Blog/Recent-Posts/1/Gallery/1.jpg';
import post1gallery2 from '../assets/Blog/Recent-Posts/1/Gallery/2.jpg';
import post1gallery3 from '../assets/Blog/Recent-Posts/1/Gallery/3.jpg';
import post1gallery4 from '../assets/Blog/Recent-Posts/1/Gallery/4.jpg';
import post1gallery5 from '../assets/Blog/Recent-Posts/1/Gallery/5.jpg';
import post1gallery6 from '../assets/Blog/Recent-Posts/1/Gallery/6.jpg';
import post1comment1 from '../assets/Blog/Recent-Posts/1/Comments/Pic-1.jpg';
import post1comment2 from '../assets/Blog/Recent-Posts/1/Comments/Pic-2.jpg';
import post1comment3 from '../assets/Blog/Recent-Posts/1/Comments/Pic-3.jpg';

import post2 from '../assets/Blog/Recent-Posts/2/Main.jpg';
import post2gallery1 from '../assets/Blog/Recent-Posts/2/Gallery/1.jpg';
import post2gallery2 from '../assets/Blog/Recent-Posts/2/Gallery/2.jpg';
import post2gallery3 from '../assets/Blog/Recent-Posts/2/Gallery/3.jpg';
import post2gallery4 from '../assets/Blog/Recent-Posts/2/Gallery/4.jpg';
import post2gallery5 from '../assets/Blog/Recent-Posts/2/Gallery/5.jpg';
import post2gallery6 from '../assets/Blog/Recent-Posts/2/Gallery/6.jpg';
import post2comment1 from '../assets/Blog/Recent-Posts/2/Comments/Pic-1.jpg';
import post2comment2 from '../assets/Blog/Recent-Posts/2/Comments/Pic-2.jpg';
import post2comment3 from '../assets/Blog/Recent-Posts/2/Comments/Pic-3.jpg';

import post3 from '../assets/Blog/Recent-Posts/3/Main.jpg';
import post3gallery1 from '../assets/Blog/Recent-Posts/3/Gallery/1.jpg';
import post3gallery2 from '../assets/Blog/Recent-Posts/3/Gallery/2.jpg';
import post3gallery3 from '../assets/Blog/Recent-Posts/3/Gallery/3.jpg';
import post3gallery4 from '../assets/Blog/Recent-Posts/3/Gallery/4.jpg';
import post3gallery5 from '../assets/Blog/Recent-Posts/3/Gallery/5.jpg';
import post3gallery6 from '../assets/Blog/Recent-Posts/3/Gallery/6.jpg';
import post3comment1 from '../assets/Blog/Recent-Posts/3/Comments/Pic-1.jpg';
import post3comment2 from '../assets/Blog/Recent-Posts/3/Comments/Pic-2.jpg';
import post3comment3 from '../assets/Blog/Recent-Posts/3/Comments/Pic-3.jpg';



const Images = {

    /*Global*/
    pageTitle,

    /*Home*/
    home,

    /*About*/
    about1,
    about2,
    about3,
    about4,

    /*Speakers*/
    speaker1,
    speaker2,
    speaker3,
    speaker4,
    speaker5,
    speaker6,
    speaker7,
    speaker8,

    /*Testimonials*/
    testimonial1,
    testimonial2,
    testimonial3,

    /*Sponsors*/
    platinumSponsor1,
    platinumSponsor2,
    platinumSponsor3,
    platinumSponsor4,

    goldSponsor1,
    goldSponsor2,
    goldSponsor3,
    goldSponsor4,

    silverSponsor1,
    silverSponsor2,
    silverSponsor3,
    silverSponsor4,

    /*Gallery*/
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,

    /*Blogs*/
    blog1,
    blog1gallery1,
    blog1gallery2,
    blog1gallery3,
    blog1gallery4,
    blog1gallery5,
    blog1gallery6,
    blog1comment1,
    blog1comment2,
    blog1comment3,

    blog2,
    blog2gallery1,
    blog2gallery2,
    blog2gallery3,
    blog2gallery4,
    blog2gallery5,
    blog2gallery6,
    blog2comment1,
    blog2comment2,
    blog2comment3,

    blog3,
    blog3gallery1,
    blog3gallery2,
    blog3gallery3,
    blog3gallery4,
    blog3gallery5,
    blog3gallery6,
    blog3comment1,
    blog3comment2,
    blog3comment3,

    blog4,
    blog4gallery1,
    blog4gallery2,
    blog4gallery3,
    blog4gallery4,
    blog4gallery5,
    blog4gallery6,
    blog4comment1,
    blog4comment2,
    blog4comment3,

    blog5,
    blog5gallery1,
    blog5gallery2,
    blog5gallery3,
    blog5gallery4,
    blog5gallery5,
    blog5gallery6,
    blog5comment1,
    blog5comment2,
    blog5comment3,

    blog6,
    blog6gallery1,
    blog6gallery2,
    blog6gallery3,
    blog6gallery4,
    blog6gallery5,
    blog6gallery6,
    blog6comment1,
    blog6comment2,
    blog6comment3,

    post1,
    post1gallery1,
    post1gallery2,
    post1gallery3,
    post1gallery4,
    post1gallery5,
    post1gallery6,
    post1comment1,
    post1comment2,
    post1comment3,

    post2,
    post2gallery1,
    post2gallery2,
    post2gallery3,
    post2gallery4,
    post2gallery5,
    post2gallery6,
    post2comment1,
    post2comment2,
    post2comment3,

    post3,
    post3gallery1,
    post3gallery2,
    post3gallery3,
    post3gallery4,
    post3gallery5,
    post3gallery6,
    post3comment1,
    post3comment2,
    post3comment3,

};

export default Images;