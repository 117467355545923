import React from 'react';

import { PageTitle } from '../../../components';
import { Testimonials } from '../../../sections';



const TestimonialsPage = () => { 
  return (
    <>
      <PageTitle title={'testimonials'} page={'testimonials'} />
      <Testimonials />
    </>
 
  ) 
} 

export default TestimonialsPage;