import React from 'react';
import './Faqs.css';

import { PageTitle, FaqItem, Heading } from '../../../components';
import { faqsContent } from '../../../constants';


  
const Faqs = () => {  
    
  return ( 
      <>
        <PageTitle title={'faqs'} page={'faqs'} />
        <section className='faq'>

            <Heading title={'frequently asked questions'} /> 

            <div className='accordion-container'> 
            {
                faqsContent.map((faq) => {
                    return (
                        <FaqItem key={faq.id} title={faq.heading} content={faq.content} />
                    )
                })
            }
            </div>

        </section>
      </>
    )

}

export default Faqs;