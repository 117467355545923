import React from 'react';
import './Logo.css';
 
import { Link } from "react-router-dom";

  

const Logo = () => {  
  return (
    <Link to='/'  className='logo'>
      {/* <img src={images.logo} alt='header-logo' /> */}
      <h2>Eventia</h2>
    </Link> 
  )
}
 
export default Logo;