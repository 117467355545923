import React from 'react'; 
import './Speakers.css'; 

import { Heading, SpeakerItem } from '../../../components';
import { speakersContent } from '../../../constants';
 
   
   
const Speakers = () => {  
  return (
    <section className='speakers'> 

      <Heading title={'event speakers'} /> 

      <div className='box-container'> 
      { 
        speakersContent.map((speaker) => { 
        return(
          <SpeakerItem 
            key={speaker.id} 
            image={speaker.image}  
            name={speaker.name}
            title={speaker.title}
            accounts={speaker.accounts}
            speaker={speaker}  
          />)
        })
      } 
      </div>

    </section>
  )
}

export default Speakers;