 const tickets = [ 
    {   
        id: 1,  
        title: 'regular pass',
        price: 25,
        list: [
            {id:1, name: '1-Day Conference'},
            {id:2, name: '1 workshop'},
            {id:3, name: 'seats'},
            {id:4, name: 'certificates'},
            {id:5, name: 'free coffee'},
        ],
    },
    {  
        id: 2, 
        title: 'business pass',
        price: 50,
        list: [
            {id:1, name: '2-Day Conference'},
            {id:2, name: '3 workshop'},
            {id:3, name: 'seats'},
            {id:4, name: 'certificates'},
            {id:5, name: 'free coffee & lunch'},
        ],
    },
    {  
        id: 3, 
        title: 'premium pass',
        price: 75,
        list: [
            {id:1, name: '3-Day Conference'},
            {id:2, name: '5 workshop'},
            {id:3, name: 'seats'},
            {id:4, name: 'certificates'},
            {id:5, name: 'free coffee & lunch'},
        ],
    },
 
];

export default tickets;