import React, {useState} from 'react';
import './Gallery.css';
 
import { GalleryItem, Heading, PageTitle } from '../../../components';
import { galleryContent } from '../../../constants';

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
   
    
const Gallery = () => { 

  // State
  const [index, setIndex] = useState(-1);

	// Handler
  const currentImage = galleryContent[index]; 
  const nextIndex = (index + 1) % galleryContent.length;
  const nextImage = galleryContent[nextIndex] || currentImage;
  const prevIndex = (index + galleryContent.length - 1) % galleryContent.length;
  const prevImage = galleryContent[prevIndex] || currentImage;

  const openLightbox = (index) => setIndex(index);
  const closeLightbox = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <>

      <PageTitle title={'Our Gallery'} page={'Our Gallery'} /> 

      <section className='gallery'>

        <Heading title={'gallery'} /> 

        <div className='box-container'>
          {
            galleryContent.map((item) => {
              return(
                <GalleryItem
                key={item.id}
                id={item.id} 
                image={item.image}
                title={item.title}
                popup={openLightbox}
                /> 

              ) 
            })
          }

{index !== -1 && (
        <Lightbox
          mainSrc={currentImage.image}
          imageTitle={currentImage.title}
          mainSrcThumbnail={currentImage.image}
          nextSrc={nextImage.image}
          nextSrcThumbnail={nextImage.image}
          prevSrc={prevImage.image}
          prevSrcThumbnail={prevImage.image}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
        )} 
            
        </div>

      </section>
    </>

  )
}

export default Gallery;