import { images } from '../constants';

export const categories = [  
    {
        id: 1,
        title: 'Machine Learning', 
        quantity: '20',
    },
    {
        id: 2,
        title: 'Business event',
        quantity: '5',
    }, 
    {
        id: 3, 
        title: 'digital marketing',
        quantity: '10',
    },
    { 
        id: 4,
        title: 'Application Development',
        quantity: '15',
    },
    { 
        id: 5,
        title: 'Data Modeling',
        quantity: '8',
    },
    {
        id: 6,
        title: 'Programming Languages',
        quantity: '4',
    },
    {
        id: 7,
        title: 'Security Standards',
        quantity: '3',
    },
];

export const posts = [ 
    {   
        id: 1, 
        image: images.post1,
        date : '10 oct, 2023',
        admin: 'admin',
        duration: '3 mins',
        heading: 'How IT has changed the world',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post1gallery1},
            {id: 2, image: images.post1gallery2},
            {id: 3, image: images.post1gallery3},
            {id: 4, image: images.post1gallery4},
            {id: 3, image: images.post1gallery5},
            {id: 4, image: images.post1gallery6},
        ],
        tags: [ 
            {id: 1, title: 'AI'},
            {id: 2, title: 'Technology'},
            {id: 3, title: 'Security'},
        ],
        comments: [
            {
                id: 1,
                image: images.post1comment1,
                name: 'javeed Raheem',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post1comment2,
                        name: 'emily warner',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.post1comment3,
                name: 'john david',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 2, 
        image: images.post2,
        date : '10 oct, 2023',
        admin: 'admin',
        duration: '4 mins',
        heading: '5 Upcoming Tech Events to attend in 2022',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post2gallery1},
            {id: 2, image: images.post2gallery2},
            {id: 3, image: images.post2gallery3},
            {id: 4, image: images.post2gallery4},
            {id: 5, image: images.post2gallery5},
            {id: 6, image: images.post2gallery6},
        ],
        tags: [ 
            {id: 1, title: 'AI'},
            {id: 2, title: 'Technology'},
            {id: 3, title: 'Security'},
        ],
        comments: [
            {
                id: 1,
                image: images.post2comment1,
                name: 'javeed Raheem',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post2comment2,
                        name: 'emily warner',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.post2comment3,
                name: 'john david',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.post3,
        date : '10 oct, 2023',
        admin: 'admin',
        duration: '3 mins',
        heading: 'Apps you use in your day-to-day functions',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para5: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para6: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post3gallery1},
            {id: 2, image: images.post3gallery2},
            {id: 3, image: images.post3gallery3},
            {id: 4, image: images.post3gallery4},
            {id: 5, image: images.post3gallery5},
            {id: 6, image: images.post3gallery6},
        ],
        tags: [ 
            {id: 1, title: 'AI'},
            {id: 2, title: 'Technology'},
            {id: 3, title: 'Security'},
        ],
        comments: [
            {
                id: 1,
                image: images.post3comment1,
                name: 'javeed Raheem',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post3comment2,
                        name: 'emily warner',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.post3comment3,
                name: 'john david',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export const tags = [
    {
        id: 1,
        title: 'marketing',
    },
    {
        id: 2,
        title: 'business',
    },
    {
        id: 3,
        title: 'event',
    },
    {
        id: 4,
        title: 'development', 
    },
    {
        id: 5,
        title: 'tech', 
    },
    {
        id: 6,
        title: 'Entrepreneur',
    }, 
    {
        id: 7,
        title: 'Blockchain',
    }, 
];