import React from 'react'
import './Blogs.css';

import { Blog, Heading } from '../../../components';
import { blogContent } from '../../../constants';

 
  
const Blogs = () => {   
 
  const size = 3;
  const blogs = blogContent.slice(0, size);  

  return (
    <section className='blog main' id='blog'>
 
      <Heading title={'my blogs'} />
 
      <div className='box-container'> 
      {  
        blogs.map((blog) => { 
          return( 
            <Blog  
              key={blog.id} 
              image={blog.image} 
              date={blog.date}
              heading={blog.heading}
              content={blog.content} 
              blog={blog} 
            />)
        })
      }
      </div>

    </section>
  )
}

export default Blogs;