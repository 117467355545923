import React from 'react';

import { PageTitle, Contact } from '../../components';



const ContactPage = () => { 
    return ( 
    <>
      <PageTitle title={'contact us'} page={'contact'} />
      <Contact />
    </>
   )
}
 
 export default ContactPage; 