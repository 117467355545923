import React from 'react';
import './BlogSingle.css';

import { BlogSidebar, Button, Comments, PageTitle } from '../../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaUserAlt, FaClock, FaComment } from 'react-icons/fa';

import { useLocation } from "react-router-dom";
 
    

const BlogSingle = () => {  

  const location = useLocation();
  const blog = location.state;  

  const paragraphs = blog.paragraph; 

  const blogComments = blog.comments; 

  function countCommentsAndReplies(commentss) {
    let count = 0;
    commentss.forEach(comment => {
      count++; // add 1 for the comment itself
      if (comment.replies && comment.replies.length > 0) {
        count += countCommentsAndReplies(comment.replies); // add count of replies recursively
      }
    });
    return count;
  }

  let totalCommentsAndReplies = 0;
  if (blogComments) {
    totalCommentsAndReplies = countCommentsAndReplies(blogComments);
  }

  return (   
    <>
      <PageTitle title={'blog single'} page={'blog single'} />
      <section className='blog-single'>

        <div className='blog-container'>

          <div className='blog-info' key={blog.id}>

            <div className='image'>
              <img src={blog.image} alt='Blog' />
              <span className='date'>{(blog.date).substring(0, (blog.date).indexOf(','))}</span>
            </div>

            <div className='content'> 

              <h3 className='main-heading'>{blog.heading}</h3>

              <div className='details'>
                <h5><FaUserAlt className='icon' /><span>{blog.admin}</span></h5>
                <h5><FaClock className='icon' /><span>{blog.duration} read</span></h5>
                <h5><FaComment className='icon' /><span>{totalCommentsAndReplies} comments</span></h5>
              </div>

              <p>{paragraphs.para1}</p>  
              <p>{paragraphs.para2}</p> 
              
              <div className='important'>
                {blog.important}
              </div>
 
              <p>{paragraphs.para3}</p>  
              <p>{paragraphs.para4}</p>  


              <div className='blog-gallery'>
              {
                (blog.gallery).map((item) => {
                  return <img key={item.id} src={item.image} alt='' />
                })
              }
              </div> 

              <p>{paragraphs.para5}</p>  
              <p>{paragraphs.para6}</p> 

              <div className='end-details'>
                <div className='tags'>
                  <h3>tags:</h3>
                  {
                    (blog.tags).map((item) => {
                      return <span key={item.id}>{item.title}</span>
                    })
                  }
                </div>

                <div className='share'>
                  <h3>share:</h3>
                  <FaFacebookF className='icon' />
                  <FaLinkedin className='icon' />
                  <FaTwitter className='icon' />
                  <FaInstagram className='icon' />
                </div>
              </div>
            </div>
          </div>
           
          <Comments blog={blog} totalComments={totalCommentsAndReplies} />

          <div className='leave-reply'>
            <form className='form' action='comment.php'>
                <h3>leave a comment</h3>  
                <div className='input-box'>
                    <input type="text" name="name" className='box' id="name" placeholder="name" required />
                    <input type="email" name="email" className='box' id="email" placeholder="email" required />
                </div>
                <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
                <button type="submit" name="submit" id="submit"><Button title={"submit"} /></button>
            </form>
          </div>

        </div>

        <BlogSidebar />  

      </section>

    </>

  )
}

export default BlogSingle;