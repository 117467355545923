import React from 'react';

import { PageTitle } from '../../../components';
import { ScheduleTab } from '../../../sections';


 
const ScheduleTabPage = () => {
 
  return (  
    <>
      <PageTitle title={'schedule tab'} page={'schedule tab'} />
      <ScheduleTab />
    </>
  )

}

export default ScheduleTabPage;