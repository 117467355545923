import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css'; 



const Button = ({title, link=''}) => {
  return (      
    <Link to={`/${link}`} className='btn'>{title}</Link>
  )  
}

export default Button; 