import React from 'react';

import { PageTitle } from '../../../components';
import { ScheduleList } from '../../../sections';



const ScheduleListPage = () => {    
  
  return (   
    <>  
      <PageTitle title={'schedule list'} page={'schedule list'} />
      <ScheduleList />
    </>
  )
  
}

export default ScheduleListPage;