import React from 'react';
import './Sponsors.css';

import { Heading } from '../../../components';
import { sponsorContent } from '../../../constants';



const Sponsors = () => {      
  return (  
    <section className='sponsors'> 

      <Heading title={'Sponsors'} />

      {
        sponsorContent.map((sponsors) => {

          return <div className='sponsors-container' key={sponsors.id}>

            <h3>{sponsors.category} sponsors</h3>

            <div className='content'>
              {(sponsors.list).map(item => {return <div className='sponsor-item' key={item.id}><img src={item.image} alt='Sponsor' /></div>})}
            </div>

          </div> 
        })
      }

    </section>
  )
}
 
export default Sponsors;