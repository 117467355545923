import React from 'react';
import './ScheduleItem.css';
 
  
 
const ScheduleItem = ({id, date, day, program}) => {
    
  return ( 
    <div className='schedule-item' key={id}> 

      <h1>day, 0{id}</h1>
      <h4>{day}, {date}</h4>
      
      { 
      (program).map((speech) => { 
      return(
        <div className={`speech-item ${speech.class}`} key={speech.id}>
          <div className='timing'>{speech.timing}</div>
          <div className='content'>
            <div className='speaker-intro'>
              <img src={speech.speakerPic} alt="" />
              <h5>{speech.speakerName}</h5>
            </div>
            <div className='topic'>
                <h3>{speech.title}</h3>
                <p>{speech.content}</p>
            </div>
          </div>
        </div>
      )
      }) 
      } 
    </div>
  )
}

export default ScheduleItem;