import React from 'react';
import './PagesNo.css';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

  

const PagesNo = () => {
  return ( 
    <div className='pages-no'>
        <div className='container'> 
            <div className='item'>  
                <BsArrowLeft />
            </div>
            <div className='numbers'>
                <div className='item active'>1</div>
                <div className='item'>2</div>
                <div className='item'>3</div>
            </div>
            <div className='item'>
                <BsArrowRight />
            </div>
        </div>

    </div>
  )
}

export default PagesNo