import React from 'react';
import './ScheduleList.css';

import { ScheduleItem, Heading } from '../../../components';
import { schedule } from '../../../constants';



const ScheduleList = () => {   
    
  return ( 
    <section className='schedule list'>

      <Heading title={'event schedule'} /> 

      <div className='box-container'>
      { 
        (schedule).map((item) => { 
          return( 
            <ScheduleItem   
              key={item.id} 
              id={item.id} 
              date={item.date}
              day={item.day}
              program={item.program}
            />)
        }) 
      } 
      </div>

    </section>
  )
}

export default ScheduleList;