import { images } from '.'; 
 
const galleryContent = [ 
    {
        id: 1,
        image: images.gallery1,
        title: 'conference 2022',
    },
    {
        id: 2,
        image: images.gallery2,
        title: 'conference 2022',
    },
    {
        id: 3,
        image: images.gallery3,
        title: 'conference 2022',
    },
    {
        id: 4,
        image: images.gallery4,
        title: 'conference 2022',
    },
    {
        id: 5,
        image: images.gallery5,
        title: 'conference 2022',
    },
    {
        id: 6,
        image: images.gallery6,
        title: 'conference 2022',
    },
    {
        id: 7,
        image: images.gallery7,
        title: 'conference 2022',
    },
    {
        id: 8,
        image: images.gallery8,
        title: 'conference 2022',
    },
    {
        id: 9,
        image: images.gallery9,
        title: 'conference 2022',
    },
];

export default galleryContent;

