import React from 'react';

import { PageTitle } from '../../../components';
import { Speakers } from '../../../sections';



const SpeakerPage = () => {
  return (
    <>
      <PageTitle title={'Speakers'} page={'Speakers'} />
      <Speakers />
    </>
  )
} 

export default SpeakerPage;