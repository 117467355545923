import { images } from '.';

const speakers = [ 
    {
        id: 1,
        image: images.speaker1,
        name: 'George Alexander',
        title: 'CEO',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
    {
        id: 2,
        image: images.speaker2,
        name: 'Michelle Goszko',
        title: 'Founder',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    },  
    {
        id: 3,
        image: images.speaker3,
        name: 'James Johnson',
        title: 'manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
    {
        id: 4,
        image: images.speaker4,
        name: 'Wendy Hills',
        title: 'manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
    {
        id: 5,
        image: images.speaker5,
        name: 'David Smith',
        title: 'manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
    {
        id: 6,
        image: images.speaker6,
        name: 'Maria Hernandez',
        title: 'manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
    {
        id: 7,
        image: images.speaker7,
        name: 'David Steele',
        title: 'manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
    {
        id: 8,
        image: images.speaker8,
        name: 'Jill Kennare',
        title: 'manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
        about: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        experience: [ 
            {id: 1, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
        testimonials: [ 
            {id: 1, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 2, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
            {id: 3, author: 'Lorem Ipsum', content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Animi Delectus Qui Perspiciatis Eaque Reprehenderit Suscipit Molestiae Ut Ratione Labore. Veritatis Commodi Repellat Beatae Ad Accusantium Et, Explicabo Inventore Quibusdam Reprehenderit!'},
        ],
    }, 
 
];

export default speakers;