import React from 'react';

import { PageTitle } from '../../../components';
import { Sponsors } from '../../../sections';



const SponsorsPage = () => { 
  return ( 
    <>
      <PageTitle title={'Sponsors'} page={'Sponsors'} />
      <Sponsors /> 
    </>

  )
} 

export default SponsorsPage;