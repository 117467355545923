import React from 'react'; 
import './About.css';

import { Button } from '../../../components';
import { images } from '../../../constants';

import { FaCheck } from 'react-icons/fa'; 
   
 

const About = () => {    
  return (
    <section className='about'>  

      <div className='box-container'>

        <div className='content'>
          <h2>Engaging minds, empowering success.</h2>
          <h5>A World of Opportunities where Anything is Possible. Facing the Challenges by Providing Expertise for changing times. </h5>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Eum voluptatibus dolores aspernatur animi perferendis iste! 
              Culpa aut enim debitis optio illum ipsum quis perferendis 
              dolor expedita. Similique necessitatibus inventore doloremque.</p>
          <ul className='services'>
              <li><FaCheck className='icon' /><span>workshop</span></li>
              <li><FaCheck className='icon' /><span>seats</span></li>
              <li><FaCheck className='icon' /><span>certificates</span></li>
              <li><FaCheck className='icon' /><span>free coffee & lunch</span></li>
          </ul>
          <Button link={'Contact-us'} title={'contact us'} />
        </div>

        <div className='image'>
          <div className='image-1'>
            <div className='image-item'><img src={images.about1} alt='' /></div>
            <div className='image-item'><img src={images.about2} alt='' /></div>
          </div>
          <div className='image-1'>
            <div className='image-item'><img src={images.about3} alt='' /></div>
            <div className='image-item'><img src={images.about4} alt='' /></div>
          </div>
        </div>
        
      </div>

    </section>
  )
}

export default About;